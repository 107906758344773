<template>
  <page-header-wrapper :title="false">
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="预约人">
                <a-input placeholder="请输入预约人姓名" v-model="queryParam.userName" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="预约人电话">
                <a-input placeholder="请输入预约人电话" v-model="queryParam.telephone" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="业务名称">
                <a-input placeholder="请输入业务名称" v-model="queryParam.workName" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item label="区域">
                  <a-cascader
                    :options="regionList"
                    placeholder="省-市-区(县)"
                    :field-names="{label: 'label', value: 'value', children: 'children' }"
                    :value="addDefaultRegion"
                    @change="changeAddRegion"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="小区">
                  <a-select
                    placeholder="请选择小区"
                    v-model="queryParam.communityId"
                  >
                    <a-select-option :key="0" :value="0">请选择小区</a-select-option>
                    <a-select-option v-for="item in regionCommunityAdd" :key="item.keyId" :value="item.id">
                      {{ item.communityName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="状态">
                  <a-select
                    v-model="queryParam.status"
                    placeholder="状态"
                    allow-clear
                  >
                    <a-select-option :key="-1" :value="-1">请选择</a-select-option>
                    <a-select-option :key="0" :value="0">未处理</a-select-option>
                    <a-select-option :key="1" :value="1">已受理</a-select-option>
                    <a-select-option :key="2" :value="2">驳回预约</a-select-option>
                    <a-select-option :key="3" :value="3">已取消</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetSearchForm()">重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!--div class="table-operator">
        <a-button type="primary" icon="plus" @click="editArticle(0)">添加</a-button>
      </div-->
      <s-table
        ref="table"
        size="default"
        rowKey="keyId"
        :columns="columns"
        :data="loadData"
      >
        <span slot="communityName" slot-scope="text, record">
          {{ record.areaCodeName }} {{ record.communityName }}
        </span>
        <span slot="reservationDate" slot-scope="text, record">
          {{ record.reservationDate }} {{ record.timeSlot }}
        </span>
        <span slot="verifyStatus" slot-scope="text, record">
          <a-tag color="#f50" v-if="record.status === 0">未处理</a-tag>
          <a-tag color="#87d068" v-else-if="record.status === 1">已受理</a-tag>
          <a-tag color="#2db7f5" v-else-if="record.status === 2">驳回预约</a-tag>
          <a-tag color="#595959" v-else>已取消</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="gotoInfo(record.keyId)">查看</a>
          <a-divider type="vertical" />
          <a @click="gotoDealInfo(record)" :disabled="record.status === 3">操作</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确认要删除此记录吗？" @confirm="delWorkRecordApi(record.keyId)">
            <a>删除</a>
          </a-popconfirm>
        </span>
      </s-table>
      <see-record ref="seerecord"></see-record>
      <deal-work @ok="handleOk" ref="dealwork"></deal-work>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import STree from '@/components/Tree/Tree'
import { STable, Ellipsis } from '@/components'
import SeeRecord from '@/views/work/components/SeeRecord'
import DealWork from '@/views/work/components/DealWork'
import { regionList } from '@/api/region'
import { regionCommunity } from '@/api/community'
import { delWorkRecord, workRecordList } from '@/api/work'
const columns = [
  {
    title: '编号',
    dataIndex: 'keyId',
    width: '100px'
  },
  {
    title: '预约人',
    dataIndex: 'userName'
  },
  {
    title: '预约人电话',
    dataIndex: 'telephone'
  },
  {
    title: '预约人时间',
    dataIndex: 'reservationDate',
    scopedSlots: { customRender: 'reservationDate' }
  },
  {
    title: '预约人业务',
    dataIndex: 'workName'
  },
  {
    title: '区域',
    dataIndex: 'communityName',
    scopedSlots: { customRender: 'communityName' }
  },
  {
    title: '状态',
    dataIndex: 'verifyStatus',
    scopedSlots: { customRender: 'verifyStatus' }
  },
  {
    title: '创建时间',
    dataIndex: 'createTime'
  },
  {
    title: '操作',
    width: '160px',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'RecordList',
  components: {
    STable,
    Ellipsis,
    STree,
    SeeRecord,
    DealWork
  },
  data () {
    this.columns = columns
    return {
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        userName: '',
        telephone: '',
        workName: '',
        status: -1,
        communityId: 0,
        areaCode: ''
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        return workRecordList(requestParameters)
          .then(res => {
            return res.result
          })
      },
      searcDate: [],
      regionList: [],
      regionCommunity: [],
      regionCommunityAdd: [],
      addDefaultRegion: []
    }
  },
  filters: {
  },
  created () {
    this.regionListApi()
  },
  watch: {
    $route (val) {
      if (val.name === 'CommunityActivitiesList') {
        this.queryParam = {
          userName: '',
          telephone: '',
          workName: '',
          status: -1,
          communityId: 0,
          areaCode: ''
        }
        this.searcDate = []
        this.addDefaultRegion = []
        this.$refs.table.refresh(true)
      }
    }
  },
  methods: {
    resetSearchForm () {
      this.queryParam = {
        userName: '',
        telephone: '',
        workName: '',
        status: -1,
        communityId: 0,
        areaCode: ''
      }
      this.searcDate = []
      this.addDefaultRegion = []
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    delWorkRecordApi (id) {
      console.log(id)
      const _this = this
      delWorkRecord({ keyId: id }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.$message.success('操作成功')
          _this.$refs.table.refresh()
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    changeTime (e) {
      this.queryParam.startTime = e[0] ? e[0] : ''
      this.queryParam.endTime = e[1] ? e[1] : ''
      this.searcDate = e
      console.log('changeTime', e)
    },
    gotoInfo (keyId) {
      this.$refs.seerecord.recordInfoApi(keyId)
    },
    gotoDealInfo (data) {
      this.$refs.dealwork.ininData(data)
    },
    handleOk (e) {
      console.log(e)
      this.$refs.table.refresh()
    },
    regionListApi () {
      const _this = this
      regionList().then((res) => {
        if (res.errorCode === 0) {
          _this.regionList = res.result
          console.log('regionList', _this.regionList)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    changeAddRegion (e) {
      this.addDefaultRegion = e
      // this.queryParam.provinceId = e[0] ? e[0] : 0
      // this.queryParam.cityId = e[1] ? e[1] : 0
      this.queryParam.areaCode = e[2] ? e[2] : 0

      this.regionCommunityApi(this.queryParam.areaCode, 1)
      this.queryParam.communityId = 0
    },
    regionCommunityApi (areaCode, type) {
      const _this = this
      regionCommunity({ areaCode: areaCode }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          if (type === 0) {
            _this.regionCommunity = res.result.data
          } else {
            _this.regionCommunityAdd = res.result.data
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>
